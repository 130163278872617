<template>
  <div class="row user-settings px-md-4">
    <div class="row">
      <div class="col-12 col-sm-12">
        <div class="mb-40px mt-14px">
          <router-link to="/settings" class="return-btn"
            ><i class="far fa-arrow-left"></i> Back to all settings</router-link
          >
        </div>
        <h2 class="main-title">
          Users
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12">
        <div
          class="card p-60px"
          :class="[height_card < 550 ? 'pb-450px' : '']"
          ref="card"
        >
          <div class="header-settings mx-0 mt-0">
            <button class="btn-blue float-right" @click="showAddUser">
              Add User
            </button>
          </div>

          <div class="row" v-if="loading == true">
            <div class="col-12 col-sm-12">
              <grid-loader
                class="expertel-loader"
                color="#ff502d"
                :loading="loading"
                :size="20"
                sizeUnit="px"
              ></grid-loader>
            </div>
          </div>

          <div class="list-table-container" v-if="loading == false">
            <ul class="users-table list-table px-0">
              <li class="list-table-row list-table-header">
                <ul>
                  <li class="list-table-column">Name</li>
                  <li class="list-table-column">Email</li>
                  <li class="list-table-column">Last Login</li>
                  <li class="list-table-column"></li>
                </ul>
              </li>
              <li
                class="list-table-row"
                v-for="(user, index) in data_users"
                :key="index"
              >
                <ul>
                  <li class="list-table-column">{{ user.name }}</li>
                  <li class="list-table-column">{{ user.email }}</li>
                  <li class="list-table-column">{{ user.last_login }}</li>
                  <li class="list-table-column justify-content-end">
                    <button
                      class="link color-gray mr-4"
                      @click="changeIDUser(user.id)"
                    >
                      Reset Password
                    </button>
                    <button
                      class="link color-gray"
                      @click="deleteUser(user.id)"
                    >
                      <i class="fas fa-times-circle color-gray"></i>
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- [[ ADD RECIPIENT MODAL ]] -->
    <transition name="fade">
      <div class="modal-expertel container-fluid" v-if="add_user === true">
        <div class="modal-container">
          <div class="header-modal">
            <button class="close-modal" @click="showAddUser()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
          <div class="body-modal">
            <h3 class="text-center mb-3"><strong>Add User</strong></h3>

            <form
              action="#"
              @submit.prevent="showAddUser"
              class="d-flex flex-column"
            >
              <input
                class="input-expertel"
                type="text"
                placeholder="Name"
                v-model="name"
              />
              <input
                class="input-expertel"
                type="text"
                placeholder="Email"
                v-model="email"
              />
              <button
                type="submit"
                class="btn button-expertel mt-4 py-3"
                @click.prevent="addNewUser()"
              >
                ADD USER
              </button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <!-- [[ ADD RECIPIENT MODAL ]] -->
  </div>
</template>
<style lang="scss" scoped>
.input-expertel {
  width: 300px;
  max-width: 100%;
}
</style>
<script>
export default {
  props: ["login_data"],
  data() {
    return {
      data_users: [],
      loading: true,
      height_card: "",
      add_user: false,
      name: "",
      email: ""
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getUsers();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    getUsers() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/users`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          self.data_users = response.data;
          self.calculateHeight();
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },

    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    changeIDUser(id) {
      this.$emit("changeIDUser", id);
    },

    showAddUser() {
      //SHOW OR NOT MODAL
      this.add_user = !this.add_user;
    },

    addNewUser() {
      const self = this;

      if (self.name == "" || self.email == "") {
        self.makeToast("Error", "Enter a valid email and name", "danger");
      } else {
        this.loading = true;
        //FROMDATA TO SEND
        let formData = new FormData();
        formData.append("user_name", self.name);
        formData.append("email", self.email);

        this.axios({
          method: "post",
          url: `https://api.expertel.ca/api/v1/client/me/users`,
          responseType: "json",
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + self.login_data.token
          }
        })
          .then(function(response) {
            if (response.data.success == false) {
              self.makeToast("Error", response.data.message, "danger");
              self.loading = false;
            } else {
              self.makeToast("Ok", "User added successfully", "success");
              self.getUsersData();
              self.name = "";
              self.email = "";
              self.loading = false;
            }
          })
          .catch(
            error => self.makeToast("ERROR", error, "danger"),
            (self.loading = false)
          );
      }
    },
    async getUsersData() {
      const self = this;
      await this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/users`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(async function(response) {
          self.data_users = response.data;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    deleteUser(id) {
      const self = this;

      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete the user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.axios({
            method: "delete",
            url: `https://api.expertel.ca/api/v1/user/${id}`,
            responseType: "json",
            headers: {
              Authorization: "Bearer " + self.login_data.token
            }
          })
            .then(async function(response) {
              if (response.data.success == false) {
                self.makeToast("ERROR", response.data.message, "danger");
                self.loading = false;
              } else {
                self.makeToast("Ok", "User was delete it", "success");
                self.getUsersData();
              }
            })
            .catch(error => this.makeToast("ERROR", error, "danger"));
        }
      });
    }
  }
};
</script>
